import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { DropdownButton, Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import user from "../../../assets/images/user.png";
import { API_URL } from "../../BaseUrlApi/Url";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faColumns,
  faUsers,
  faTicketAlt,
  faUserMd,
  faClock,
  faDatabase,
  faQrcode,
  faBell,
  faAngleLeft,
  faBars,
  faFolder,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./AdminSidebar.css";
import { LOCAL_STORAGE_KEYS } from "../../../constans";
import { Badge } from "antd";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AdminSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      sidebar: [],
      shift_id: null,
      shift_id_online: null,
      admin: null,
    };
  }

  async componentDidMount() {
    try {
      let adminName = localStorage.getItem("admin_name");
      await this.setState({
        admin: adminName,
      });

      let getShiftInfo = await axios.get(
        `${API_URL}/admin/queues/getTodaysQueueInfo`,
        { headers }
      );

      if (
        getShiftInfo.data.message !== "Tidak ada shift yang sedang berjalan"
      ) {
        this.setState({
          shift_id: getShiftInfo.data.shift.id,
        });
      }
      let getShiftInfoOnline = await axios.get(
        `${API_URL}/admin/queues/getTodaysOnlineQueueInfo`,
        { headers }
      );
      if (
        getShiftInfoOnline.data.message !== "No shifts are currently running!"
      ) {
        this.setState({
          shift_id_online: getShiftInfoOnline.data.shift.id,
        });
      }
    } catch (e) {
      let getShiftInfoOnline = await axios.get(
        `${API_URL}/admin/queues/getTodaysOnlineQueueInfo`,
        { headers }
      );
      if (
        getShiftInfoOnline.data.message !== "No shifts are currently running!"
      ) {
        this.setState({
          shift_id_online: getShiftInfoOnline.data.shift.id,
        });
      }
    }
  }
  handleStateChange(state) {
    this.setState({ iOpen: state.isOpen });
  }
  closeMenu = async () => {
    this.setState((state) => ({ isOpen: !this.state.isOpen }));
  };

  openMenu = async () => {
    this.setState((state) => ({ isOpen: !this.state.isOpen }));
  };

  logout() {
    swal({
      title: "LOG OUT",
      text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
      buttons: ["Tidak", "Ya"],
      dangerMode: true,
    }).then((removeData) => {
      if (removeData) {
        const logOutResponse = async () => {
          await axios.post(`${API_URL}/auth/logout`, [], { headers });
          localStorage.clear();
          window.location.href = "/login";
        };
        logOutResponse();
      } else {
        return;
      }
    });
  }
  render() {
    if (localStorage.getItem("access_token") === null) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          <div className="adm-navbar">
            <FontAwesomeIcon
              icon={faBars}
              className="open-icon"
              onClick={this.closeMenu}
            />
            <DropdownButton
              className="float-right"
              id="dropdown-basic-button"
              title={
                <span>
                  <span className="admin-name">{this.state.admin}</span>
                  <img src={user} className="user-icon" alt="" />
                </span>
              }
            >
              <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
            </DropdownButton>
          </div>

          <div id="outer-container" className="text-left">
            <Menu
              disableCloseOnEsc
              isOpen={this.state.isOpen}
              onStateChange={(state) => this.handleStateChange(state)}
              customCrossIcon={false}
              // noTransition
              pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
            >
              <h1 className="adm-sidebar-title ml-3">
                KLINIK <br />
                PEDIATRICA <br />
                HUSADA
              </h1>
              <div className="close-section">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="close-icon"
                  onClick={this.closeMenu}
                />
              </div>
              <a
                id="home"
                className="menu-item dropdown-sidebar"
                href="/admin-dashboard"
              >
                <FontAwesomeIcon icon={faColumns} className="mr-2" />
                Dashboard
              </a>
              <a
                id="about"
                className="menu-item dropdown-sidebar"
                href="/patients"
              >
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                Manajemen Anak
              </a>
              <a
                id="about"
                className="menu-item dropdown-sidebar"
                href="/request-certificate"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faFolder} className="mr-2" />
                Request Sertifikat
                <Badge
                  className="site-badge-count-109"
                  count={
                    localStorage.getItem(
                      LOCAL_STORAGE_KEYS.PENDING_CERTIFICATE
                    ) || 0
                  }
                  size="small"
                  style={{
                    border: "none",
                    fontWeight: "bold",
                    marginLeft: 8,
                  }}
                />
              </a>
              <a
                id="about"
                className="menu-item dropdown-sidebar"
                href="/duplicate-data"
                style={{ display: "flex", alignItems: "center" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                  className="w-6 h-6"
                >
                  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                  <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                </svg>
                Data Duplikat
              </a>
              {/* <a
                id="contact"
                className="menu-item dropdown-sidebar"
                href="/queue-praktek"
              >
                <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                Manajemen Antrian
              </a> */}
              <li className="nav-item">
                <a
                  className="nav-link collapsed dropdown-sidebar2"
                  href="#submenu1sub4"
                  data-toggle="collapse"
                  data-target="#submenu1sub4"
                >
                  <i className="fa fa-table"></i>
                  <span className="d-none d-sm-inline menu-item">
                    <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                    Manajemen Antrian
                  </span>
                </a>
                <div
                  className="collapse"
                  id="submenu1sub4"
                  aria-expanded="false"
                >
                  <ul className="flex-column nav pl-4 dropdown-data">
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a
                        className="nav-link py-0"
                        href="/queue-practicing-doctor"
                      >
                        <span className="dropdown-custom">Praktek</span>
                      </a>
                    </li>
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a
                        className="nav-link py-0"
                        href="/queue-online-consultation"
                      >
                        <span className="dropdown-custom">
                          Konsultasi Online
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <a id="home" className="menu-item dropdown-sidebar" href="/users">
                <FontAwesomeIcon icon={faUserMd} className="mr-2" />
                Manajemen Perawat
              </a>
              <a
                id="contact"
                className="menu-item dropdown-sidebar"
                href="/vaccination-schedule"
              >
                <FontAwesomeIcon icon={faClock} className="mr-2" />
                Jadwal Vaksinasi
              </a>
              <a
                id="contact"
                className="menu-item dropdown-sidebar"
                href="/visits"
              >
                <FontAwesomeIcon icon={faClock} className="mr-2" />
                Jadwal Kunjungan
              </a>

              <li className="nav-item">
                <a
                  className="nav-link collapsed dropdown-sidebar2"
                  href="#submenu1sub2"
                  data-toggle="collapse"
                  data-target="#submenu1sub2"
                >
                  <i className="fa fa-table"></i>
                  <span className="d-none d-sm-inline menu-item">
                    <FontAwesomeIcon icon={faBell} className="mr-2" />
                    Notifikasi
                  </span>
                </a>
                <div
                  className="collapse"
                  id="submenu1sub2"
                  aria-expanded="false"
                >
                  <ul className="flex-column nav pl-4 dropdown-data">
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/context">
                        <span className="dropdown-custom">Konteks</span>
                      </a>
                    </li>
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/broadcast">
                        <span className="dropdown-custom">Broadcast</span>
                      </a>
                    </li>
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/notifications">
                        <span className="dropdown-custom">Notifikasi</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed dropdown-sidebar2"
                  href="#submenu1sub3"
                  data-toggle="collapse"
                  data-target="#submenu1sub3"
                >
                  <i className="fa fa-table"></i>
                  <span className="d-none d-sm-inline menu-item">
                    <FontAwesomeIcon icon={faDatabase} className="mr-2" />
                    Master Data
                  </span>
                </a>
                <div
                  className="collapse"
                  id="submenu1sub3"
                  aria-expanded="false"
                >
                  <ul className="flex-column nav pl-4 dropdown-data">
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/vaccines">
                        <span className="dropdown-custom">Daftar Vaksin</span>
                      </a>
                    </li>
                    <li className="nav-item  menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/vaccine-types">
                        <span className="dropdown-custom">Tipe Vaksin</span>
                      </a>
                    </li>
                    <li className="nav-item  menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/shifts">
                        <span className="dropdown-custom">Shift</span>
                      </a>
                    </li>
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/blocked-dates">
                        <span className="dropdown-custom">Blocked Dates</span>
                      </a>
                    </li>
                    {/* <li className="nav-item menu-item dropdown-sidebar">
                      <a
                        className="nav-link py-0"
                        href="/blocked-dates?shift_type=konsultasi_online"
                      >
                        <span className="dropdown-custom">
                          Blocked Dates Online
                        </span>
                      </a>
                    </li> */}
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a
                        className="nav-link py-0"
                        href="/blocked-dates?shift_type=konsultasi_online"
                      >
                        <span className="dropdown-custom">
                          Blocked Dates Online
                        </span>
                      </a>
                    </li>
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/bank-accounts">
                        <span className="dropdown-custom">Rekening Bank</span>
                      </a>
                    </li>
                    <li className="nav-item menu-item dropdown-sidebar">
                      <a className="nav-link py-0" href="/terms-and-conditions">
                        <span className="dropdown-custom">
                          Syarat &amp; Ketentuan
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {this.state.shift_id_online !== null ? (
                <Link
                  className="menu-item dropdown-sidebar"
                  to={`/queues-online/7`}
                >
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  Antrian Konsultasi Online
                </Link>
              ) : (
                <Link
                  className="menu-item dropdown-sidebar"
                  to="/queues-front-online/show-admin"
                >
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  Antrian Konsultasi Online
                </Link>
              )}
              {this.state.shift_id !== null ? (
                <Link
                  className="menu-item dropdown-sidebar"
                  to={`/queues/${this.state.shift_id}`}
                >
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  Antrian Klinik
                </Link>
              ) : (
                <Link
                  className="menu-item dropdown-sidebar"
                  to="/queues-front/show-admin"
                >
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  Antrian Klinik
                </Link>
              )}
              {this.state.shift_id !== null ? (
                <Link
                  className="menu-item dropdown-sidebar"
                  target="_blank"
                  to={`/show-queues/${this.state.shift_id}`}
                >
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  Tampilkan Antrian Klinik
                </Link>
              ) : (
                <Link
                  className="menu-item dropdown-sidebar"
                  target="_blank"
                  id="contact"
                  to="/queues-front/show-public"
                >
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  Tampilkan Antrian
                </Link>
              )}
              <a
                id="contact"
                target="_blank"
                className="menu-item dropdown-sidebar"
                href="/qrcode"
              >
                <FontAwesomeIcon icon={faQrcode} className="mr-2" />
                QR Code
              </a>
              <a
                id="contact"
                target="_blank"
                className="menu-item dropdown-sidebar"
                href="/running-queue"
              >
                <FontAwesomeIcon icon={faQrcode} className="mr-2" />
                Scan Barcode
              </a>
              <div className="mb04" />
              <a
                id="contact"
                // target="_blank"
                className="menu-item dropdown-sidebar"
                href="/self-checkin"
              >
                <FontAwesomeIcon icon={faQrcode} className="mr-2" />
                Self Checkin
              </a>
              <div className="mb04" />
            </Menu>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(AdminSidebar);
