import { Breadcrumb, Button, Col, Radio, Row, Typography } from 'antd';
import { isEmpty, pick } from 'lodash';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';
import AdminSidebar from '../../../../Components/Admin/AdminSidebar/AdminSidebar';
import Spacer from '../../../../Components/Spacer';
import CustomCheckbox from '../../../../Components/V2/CustomCheckbox/CustomCheckbox';
import { TYPE_MANAGEMENT_QUEUE } from '../../../../constans';
import useGetQueue from '../../../../hooks/queries/useGetQueue';
import useGetSearchQueue from '../../../../hooks/queries/useGetSearchQueue';
import useDisclosure from '../../../../hooks/useDisclosure';
import ModalFilterQueue from './Components/ModalFilterQueue/ModalFilterQueue';
import TableQueue from './Components/TableQueue/TableQueue';
import './OnlineConsultation.css';
import { LIST_PAYMENT_STATUS } from '../../../../constans/payment-status';
const { Title, Text } = Typography;

const OnlineConsultation = () => {
  const history = useHistory();
  const urlParams = queryString.parse(window.location.search, {
    parseBooleans: true,
  });
  const allQueue = urlParams?.all;
  const paymentStatus = urlParams?.paymentStatus;
  const isFiltered = urlParams?.isFiltered;

  const modalFilter = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);
  const [filterValues, setFilterValues] = useState(null);

  const onChangeCheckBox = (event) => {
    const url = queryString.stringifyUrl({
      url: '/queue-online-consultation',
      query: {
        ...urlParams,
        all: event?.target?.checked,
      },
    });
    history.push(url);
  };

  const onFilterByPaymentStatus = (event) => {
    const isChecked = event?.target?.checked;

    const url = queryString.stringifyUrl({
      url: '/queue-online-consultation',
      query: {
        ...urlParams,
        ...(isChecked && {
          paymentStatus: event?.target?.value,
        }),
      },
    });
    history.push(url);
  };

  const { data, isLoading } = useGetQueue({
    page: currentPage,
    type: TYPE_MANAGEMENT_QUEUE.KONSULTASI_ONLINE,
    period: allQueue ? 'alltime' : 'upcoming',
    'filters[payment_status_id]': paymentStatus,
  });

  const { data: dataSearchQueue, isLoading: isLoadingQueue } =
    useGetSearchQueue({
      page: currentPage,
      type: TYPE_MANAGEMENT_QUEUE.KONSULTASI_ONLINE,
      period: filterValues?.queuePeriod,
      keyword: filterValues?.keyword,
    });

  const dataSource = useMemo(
    () => {
      if (isFiltered) {
        return dataSearchQueue?.queues?.map((item, idx) => ({
          key: idx + 1,
          ...item,
        }));
      }
      return data?.queues?.data?.map((item, idx) => ({
        key: idx + 1,
        ...item,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLoading,
      currentPage,
      isLoadingQueue,
      isFiltered,
      allQueue,
      paymentStatus,
    ]
  );

  const onResetFilter = () => {
    const newParams = pick(urlParams, ['all', 'paymentStatus']);

    const url = queryString.stringifyUrl(
      {
        url: '/queue-online-consultation',
        query: newParams,
      },
      {
        skipEmptyString: true,
      }
    );

    history.push(url);
  };

  const onNextPage = () => {
    if (currentPage <= data?.queues?.last_page) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const onPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="admin-overlay">
      <AdminSidebar />
      <LoadingOverlay active={false} spinner text="Memuat data...">
        <div className="content-section">
          <div className="online-consultation-container">
            <Row justify="space-between" align="middle">
              <Col>
                <div className="certificate-title-wrapper">
                  <Title level={3} style={{ margin: 0 }}>
                    Manajemen Antrian Konsultasi Online
                  </Title>
                  <Spacer height={8} />
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <a href="/admin-dashboard">Dashboard</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      Manajemen Antrian Konsultasi Online
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>

            <Row justify="end" gutter={[16, 16]}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: '/add-family',
                    })
                  }
                  size="large"
                >
                  Buat Data Keluarga
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: '/create-queue-online',
                    })
                  }
                  size="large"
                >
                  Buat Antrian
                </Button>
              </Col>
            </Row>

            <div className="table-header-wrapper">
              <Row justify="space-between">
                <Col>
                  <CustomCheckbox
                    checked={allQueue}
                    onChange={onChangeCheckBox}
                    label="Tampilkan seluruh antrian"
                    desc={
                      <span className="text-danger">
                        (Pengambilan data akan membutuhkan waktu sedikit lebih
                        lama)
                      </span>
                    }
                  />
                </Col>

                <Col>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={onResetFilter}
                        size="large"
                      >
                        Reset Pencarian
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={modalFilter.onOpen}
                        size="large"
                      >
                        Cari Data
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Spacer height={24} />

              <Row align="middle" gutter={[16, 16]}>
                <Col>
                  <Text>Filter Status Pembayaran</Text>
                </Col>
                <Col>
                  <div className="">
                    <Radio.Group
                      defaultValue={+paymentStatus}
                      buttonStyle="solid"
                      style={{
                        display: 'flex',
                      }}
                      onChange={onFilterByPaymentStatus}
                    >
                      {LIST_PAYMENT_STATUS.map((payment) => (
                        <Radio.Button value={payment.id}>
                          {payment.name}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                </Col>
              </Row>

              <Spacer height={24} />

              <Row>
                <Col span={24}>
                  <TableQueue
                    pagination={!isFiltered}
                    isLoading={isLoading || isLoadingQueue}
                    dataSource={dataSource}
                    from={data?.queues?.from}
                    to={data?.queues?.to}
                    total={data?.queues?.total}
                    page={currentPage}
                    lastPage={data?.queues?.last_page}
                    onNextPage={onNextPage}
                    onPrevPage={onPrevPage}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </LoadingOverlay>

      <ModalFilterQueue
        isOpen={modalFilter.isOpen}
        onClose={modalFilter.onClose}
        onSubmit={(values) => setFilterValues(values)}
      />
    </div>
  );
};

export default OnlineConsultation;
