import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Typography,
} from "antd";
import locale from "antd/es/date-picker/locale/id_ID";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { QUEUE_PERIOD_OPTIONS } from "../../../../../../constans/options";
import useGetShifts from "../../../../../../hooks/queries/useGetShifts";
import { omit, size } from "lodash";
const { Title, Text } = Typography;

const INITIAL_VALUES_FORM = {
  queuePeriod: null,
  findBy: null,
  patientName: "",
  queueDate: null,
};

const FIND_BY_TYPE = {
  NAME: "NAME",
  QUEUE_DATE: "QUEUE_DATE",
};

const ModalFilterQueue = ({ isOpen, onClose, onSubmit }) => {
  const urlParams = queryString.parse(window.location.search, {
    parseNumbers: true,
  });

  const history = useHistory();
  const [form] = Form.useForm();

  const [filterValue, setFilterValue] = useState(INITIAL_VALUES_FORM);

  const onChangeValues = (value) =>
    setFilterValue({ ...filterValue, ...value });

  const onFinish = (values) => {
    const newParams = { ...urlParams, ...values, isFiltered: true };

    const queueDate = moment(values?.queueDate)?.isValid()
      ? moment(values.queueDate).format("YYYY-MM-DD")
      : null;

    const url = queryString.stringifyUrl(
      {
        url: "/queue-online-consultation",
        query: newParams,
      },
      {
        skipEmptyString: true,
      }
    );

    const isFindByDate = values?.findBy === FIND_BY_TYPE.QUEUE_DATE;

    const keyword = isFindByDate ? queueDate : values?.patientName;

    values["keyword"] = keyword;
    delete values[isFindByDate ? "queueDate" : "patientName"];

    onSubmit(values);
    history.push(url);
    onClose();
  };

  useEffect(() => {
    const filterParams = omit(urlParams, ["all"]);
    const filtered = {
      ...filterParams,
      ...urlParams,
    };

    const keyword =
      filtered?.findBy === FIND_BY_TYPE.QUEUE_DATE
        ? filtered["queueDate"]
        : filtered["patientName"];

    filtered["keyword"] = keyword;
    delete filtered[filtered?.findBy ? "queueDate" : "patientName"];

    setFilterValue(filtered);
    onChangeValues(filtered);
    onSubmit(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size(urlParams)]);

  return (
    <Modal
      title={
        <Title level={4} style={{ margin: 0, textAlign: "center" }}>
          Pilih Jenis Pencarian
        </Title>
      }
      open={isOpen}
      footer={false}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={filterValue}
        onValuesChange={onChangeValues}
        onFinish={onFinish}
      >
        <Form.Item
          label="Periode Antrian"
          name="queuePeriod"
          requiredMark
          rules={[
            {
              required: true,
              message: "Tidak Boleh Kosong !",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Pilih Periode Antrian"
            options={QUEUE_PERIOD_OPTIONS}
          />
        </Form.Item>

        <Form.Item
          label=""
          name="findBy"
          rules={[
            {
              required: true,
              message: "Tidak Boleh Kosong !",
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={FIND_BY_TYPE.NAME}>Cari berdasarkan Nama</Radio>
              <Radio value={FIND_BY_TYPE.QUEUE_DATE}>
                Cari berdasarkan Tanggal Antrian
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        {filterValue?.findBy === FIND_BY_TYPE.NAME && (
          <Form.Item label="" name="patientName">
            <Input size="large" placeholder="Masukkan Nama Pasien" />
          </Form.Item>
        )}

        {filterValue?.findBy === FIND_BY_TYPE.QUEUE_DATE && (
          <Form.Item label="" name="queueDate">
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              locale={locale}
              placeholder="Pilih Tanggal"
              format="dddd, DD MMMM YYYY"
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" block>
            Cari Data
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalFilterQueue;
