export const TYPE_MANAGEMENT_QUEUE = {
  KONSULTASI_ONLINE: "konsultasi_online",
  PRAKTEK_OFFLINE: "praktek_offline",
};

export const GENDER = {
  male: "Laki - laki",
  female: "Perempuan",
};

export const QUEUE_PERIOD_TYPE = {
  ALL_TIME: "alltime",
  UPCOMING: "upcoming",
};

export const LOCAL_STORAGE_KEYS = {
  PENDING_CERTIFICATE: "pending_certificate",
};
