import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import ICCertificateFill from "../../../assets/icons/certificate-fill.svg";
import useGetDailyVisitor from "../../../hooks/queries/useGetDailyVisitor";
import useGetPendingCertificate from "../../../hooks/queries/useGetPendingCertificate";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import "./AdminDashboard.css";
import CardDashboard from "./Components/CardDashboard";
import { useHistory } from "react-router-dom";
import { STATUS_CERTIFICATE } from "../../../constans/status-certificate";
import reduce from "lodash/reduce";
import { LOCAL_STORAGE_KEYS } from "../../../constans";

const AdminDashboard = () => {
  const history = useHistory();

  const { data: dailyVisitor, isLoading: isLoadingDailyVisitor } =
    useGetDailyVisitor();
  const {
    data: dataPendingCertificate,
    isLoading: isLoadingPendingCertificate,
  } = useGetPendingCertificate();

  const isLoading = isLoadingDailyVisitor || isLoadingPendingCertificate;

  const dailyVisitorDate = moment(dailyVisitor?.date).format("DD-MM-YYYY");

  const resultPatientToday = reduce(
    dailyVisitor?.visitorByShift,
    (prev, { count }) => prev + count,
    0
  );

  useEffect(() => {
    if (!isLoadingPendingCertificate)
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.PENDING_CERTIFICATE,
        dataPendingCertificate?.count || 0
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPendingCertificate]);

  return (
    <div className="admin-overlay">
      <AdminSidebar />
      <LoadingOverlay active={isLoading} spinner text="Memuat data...">
        <div className="content-section">
          <h1 className="dashboard-date ml-5">
            Tanggal Hari Ini : {dailyVisitorDate}
          </h1>
          <h2 className="dashboard-date ml-5">
            Total Pasien Hari Ini : {resultPatientToday || 0}
          </h2>

          <div className="dashboard-card text-center">
            {dailyVisitor?.date !== null && (
              <>
                {dailyVisitor?.visitorByShift.map((res, idx) => (
                  <CardDashboard
                    key={idx}
                    icon={
                      <FontAwesomeIcon icon={faUsers} className="card-icon" />
                    }
                    title={`Total Pasien ${res?.name || ""} (Orang)`}
                    count={res.count}
                  />
                ))}
              </>
            )}

            <CardDashboard
              onClick={() =>
                history.push({
                  pathname: "/request-certificate",
                  search: `?status=${STATUS_CERTIFICATE.WAITING_FOR_SIGNING}`,
                })
              }
              icon={
                <img
                  src={ICCertificateFill}
                  alt="certificate-fill.svg"
                  className="card-icon"
                />
              }
              title="Pending Certificate"
              count={dataPendingCertificate?.count || 0}
            />
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default AdminDashboard;
